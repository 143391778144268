import * as React from 'react'
import {
	Label as ReactAriaLabel,
	type LabelProps as ReactAriaLabelProps,
} from 'react-aria-components'

import { cn } from '#app/utils/misc.tsx'

export const Label = React.forwardRef<
	React.ElementRef<typeof ReactAriaLabel>,
	React.ComponentPropsWithoutRef<typeof ReactAriaLabel>
>(({ className, ...props }: ReactAriaLabelProps, ref) => (
	<ReactAriaLabel
		ref={ref}
		className={cn(
			'text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70',
			className,
		)}
		{...props}
	/>
))

Label.displayName = 'Label'
