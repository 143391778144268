import {
	useCallback,
	useRef,
	useEffect,
	forwardRef,
	type TextareaHTMLAttributes,
} from 'react'
import { TextArea as ReactAriaTextArea } from 'react-aria-components'
import { cn } from '#app/utils/misc.tsx'

export interface TextareaProps
	extends TextareaHTMLAttributes<HTMLTextAreaElement> {
	height?: number
}

const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
	({ className, ...props }) => {
		let inputRef = useRef<HTMLTextAreaElement>(null)

		let onHeightChange = useCallback(() => {
			// Quiet textareas always grow based on their text content.
			// Standard textareas also grow by default, unless an explicit height is set.
			if (!props.height && inputRef.current) {
				let input = inputRef.current
				let prevAlignment = input.style.alignSelf
				let prevOverflow = input.style.overflow
				// Firefox scroll position is lost when overflow: 'hidden' is applied so we skip applying it.
				// The measure/applied height is also incorrect/reset if we turn on and off
				// overflow: hidden in Firefox https://bugzilla.mozilla.org/show_bug.cgi?id=1787062
				let isFirefox = 'MozAppearance' in input.style
				if (!isFirefox) {
					input.style.overflow = 'hidden'
				}
				input.style.alignSelf = 'start'
				input.style.height = 'auto'
				// offsetHeight - clientHeight accounts for the border/padding.
				input.style.height = `${
					input.scrollHeight + (input.offsetHeight - input.clientHeight)
				}px`
				input.style.overflow = prevOverflow
				input.style.alignSelf = prevAlignment
			}
		}, [inputRef, props.height])

		let onFocusEvent = useCallback(() => {
			if (inputRef.current) {
				inputRef.current?.setSelectionRange(
					inputRef.current.value.length,
					inputRef.current.value.length,
				)
			}
		}, [inputRef])

		useEffect(() => {
			if (inputRef.current) {
				onHeightChange()
				onFocusEvent()
			}
		}, [onHeightChange, inputRef, props.value, onFocusEvent])

		return (
			<ReactAriaTextArea
				ref={inputRef}
				className={cn(
					[
						'text-md flex max-h-[400px] w-full rounded-md border border-disabled-grey bg-white/50 px-1 py-2 shadow-[0_0_6px_0_rgba(229,229,229,1)] ring-offset-white transition-colors [-ms-overflow-style:"none"] [scrollbar-width:"none"] dark:border-grey dark:bg-elysium/50 dark:text-white dark:ring-offset-slate-950 md:text-sm [&::-webkit-scrollbar]:hidden',
						// Placeholder
						'placeholder:text-grey dark:placeholder:text-light-grey',
						// Focus
						'focus:text-black focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2 dark:focus:text-white dark:focus-visible:ring-slate-300',
						// Disabled
						'disabled:cursor-not-allowed disabled:opacity-50',
					],
					className,
				)}
				{...props}
			/>
		)
	},
)
Textarea.displayName = 'Textarea'

export { Textarea }
